import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Brand } from 'src/app/classes/brand';
import { BrandService } from 'src/app/services/brand.service';
import { Users } from 'src/app/classes/users';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/classes/category';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}


export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard' ,icon: 'ni-tv-2', class: '' },  
    { path: '/add-user', title: 'Aggiungi cliente', icon: 'ni-fat-add', class: '' },  
    
];
export const CLIENT: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard' ,icon: 'ni-tv-2', class: '' },  
  
];
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isAdmin: boolean = false;
  private categories: Category[] = [];
  
  private secondaryStyle;
  private secondaryBorder;
  public logo;
  private id: number;

  public menuItems: any[];
  public menuClient: any[];
  public isCollapsed = true;

  constructor(
    private brandService: BrandService,
    private auth: AuthService, 
    private catService: CategoryService, 
    private router: Router) {
      auth.usersignedin.subscribe(
        (user: Users) => {
          this.id = user.id;
        }
      )
    }

  ngOnInit() {
    if(this.auth.isUserAdmin()){
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    const user = this.auth.getUser();
    this.id = user.id
    this.brandService.definitions(this.id).subscribe(
      a => {
        this.secondaryBorder = {
          'border-color':  a['data'][0].secondary,
        }
        this.secondaryStyle = {
          'color':  a['data'][0].secondary,
        }
        
        this.logo = a['data'][0].logo
      }
    )

    try{
      this.catService.doPrimary(this.id).subscribe(
        cat => this.categories = cat,
      )
    } catch(e){
      console.log(e);
    }
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.menuClient = CLIENT.filter(menuClient => menuClient);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

  setSecondaryBorder(){
    return this.secondaryBorder;
  }

  setSecondaryStyle(){
    return this.secondaryStyle;
  }
  showCatDetail(cat) {
    this.router.navigate(['user/cat/',this.id, cat.id]);
    
  }
  logout(e) {
    e.preventDefault();
    this.auth.logOut();
    this.router.navigate(['login']);
  }
}
