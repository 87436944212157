export class Users implements Users {
    id: number;
    name: string;
    email: string;
    password: string;
    delete: number;
    token?:string;
    constructor() {
        this.id = 0;
        this.name = '';
        this.email = '';
        this.password = '';
        this.delete = 0;
    }
}
