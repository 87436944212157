import { Injectable, Output, EventEmitter } from '@angular/core';

import { HttpClient, HttpHeaderResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Users } from '../classes/users';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface Jwt{
  access_token: string,
  token_type: string,
  expires_in : number,
  user_name: string,
  email: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http:HttpClient, private router : Router) { }
  
  private isUserLogged = false;
  private isCustomer = false;
  private isAdmin = false;
  
  
  @Output() usersignedin = new EventEmitter<Users>();
  @Output() usersignedup = new EventEmitter<Users>();
  @Output() userlogout = new EventEmitter<Users>();
  
  private APIAUTHURL = environment.restUrl+'/'
  
  isUserLoggedId() {
    this.isUserLogged = !! localStorage.getItem('token');
    return this.isUserLogged;
  }
  isUserAdmin() {
    if ( localStorage.getItem('permission') == 'admin'  ){
      return this.isAdmin = true;
    } 
  }

  signIn(email: string, password: string) { //LOGIN
    this.http.post(this.APIAUTHURL + 'login', {
      email: email,
      password: password
    }).subscribe(
      
      (payload: any) => {
        localStorage.setItem('token', payload.access_token); // email replace con payload...
        
        localStorage.setItem('user', JSON.stringify(payload));
        let user = new Users();
        user.name = payload.user_name;
        user.email = payload.email;
        this.usersignedin.emit(user);

        const store = JSON.stringify(payload);
        const permission = JSON.parse(store);
        
        if(permission['permission'][0]=='customer'){  
          localStorage.setItem("permission","customer");
          this.isCustomer = true;
        } else if ( permission['permission'][0] == 'admin'  ){
          localStorage.setItem("permission","admin");
          this.isAdmin = true;
        } else {
          alert("OSP! non sei autorizzato");
        }

        this.router.navigate(['dashboard']);
        return true;
        
    },
    (httpResp: HttpErrorResponse) => {          
        console.log(httpResp.message)
      },
    )
    
  }
  signUp(username: string, email: string, password: string) {
    
    const user = new Users();
    user.name = username;
    user.email = email;

    this.http.post(this.APIAUTHURL + 'signup', {
      email: email,
      name: username,
      password: password
    }).subscribe(
      (payload: Jwt) => {
        localStorage.setItem('token', payload.access_token); // email replace con payload...
        console.log(payload);
        localStorage.setItem('user', JSON.stringify(payload));
        this.usersignedup.emit(user);
    },
      (httpResp: HttpErrorResponse) =>  {
        console.log(httpResp.message)
      }
    )



    this.usersignedup.emit(user);
    return true;


  }
  
  logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('permission');
    this.userlogout.emit();
    this.isUserLogged = false;
  }

  getUser() : Users
  {
   const data = JSON.parse(localStorage.getItem('user'));
   let user = new Users();
   if(data) {
     user.name = data['user_name'];
     user.email = data['email'];
     user.id = data['ID'];
   }
   return user;
  }

  getToken(){
    return localStorage.getItem('token');
  }  

  recovery(email: string) {
		return this.http.post(this.APIAUTHURL + 'emailtoken?email='+email, {});
	}
  
  validToken(id: string, token: string) {
    return this.http.post(this.APIAUTHURL + 'validtoken?id='+id+'&token='+token, {});
  }

  newPassword(password, email, token, confirm_password):Observable<any> {
    return this.http.post(this.APIAUTHURL + 'verifytoken?email='+email+'&token='+token+'&password='+password+'&confirm_password='+confirm_password, {});
  }

}
  