import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from '../classes/category';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  cat: Category[] = [];
  private CAT = environment.restUrl+'/client/cat';
  
  constructor(private http: HttpClient) {}

  
  getCat() {
		return this.http.post(this.CAT+'s', {});
  }
  
  doCat(cat_id: number):Observable<any> {
    var formData = {			
			cat_id: cat_id,	
		}
		return this.http.post(this.CAT, formData, {});
  }
  userCat(client_id: number):Observable<any> {
    var formData = {			
			client_id: client_id,	
		}
		return this.http.post(this.CAT + 's/foruser', formData, {});
  }
  newCat(cat){
    return this.http.post(this.CAT + '/new', cat, {});
  }
  updateCat(category) {
		return this.http.post( this.CAT + '/edit', category, {});
	}

  doPrimary(client_id: number):Observable<any> {
    var formData = {			
			client_id: client_id,	
		}
		return this.http.post(this.CAT + '/primary', formData, {
    });
	}
  doRelatioship(client_id, cat_id){
    var formData = {			
      client_id: client_id,	
      cat_id: cat_id,	
		}
    return this.http.post(this.CAT + 's/foruser/relationship', formData, {});
  }
  doChild(client_id: number, parent_id: number):Observable<any> {
    var formData = {			
      client_id: client_id,	
      parent_id: parent_id,	
		}
		return this.http.post(this.CAT + '/child', formData, {});
  }
  deleteCat(id){
    return this.http.post(this.CAT + '/delete?id=' + id,{})
	}
}
