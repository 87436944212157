import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { Users } from "../interface/users";
import { AuthService } from "../services/auth.service";
import { Injectable } from "@angular/core";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private currentUserSubject: BehaviorSubject<Users>;
    constructor(
        private authenticationService: AuthService
    ) {
        this.currentUserSubject = new BehaviorSubject<Users>(JSON.parse(localStorage.getItem('currentUser')));
     }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser = this.authenticationService;

        if (currentUser.isUserLoggedId() && currentUser.getToken()) {
            request = request.clone({
                setHeaders: {
                    'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${currentUser.getToken()}`
                }
            });
        } else{
            localStorage.removeItem('user');
            this.currentUserSubject.next(null);
        }
        return next.handle(request);
    }
}
