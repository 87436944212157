import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Users } from 'src/app/classes/users';
import { BrandService } from 'src/app/services/brand.service';
import { Brand } from 'src/app/classes/brand';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public username: string;
  public definitions: Brand['definitions'];
  private primaryStyle;
  private secondaryStyle;
  private secondaryBorder;
  private id: number;
  private isUserLoggedIn = false;

  constructor(
    private brandService: BrandService,
    private auth: AuthService, 
    private router : Router) {

      auth.usersignedin.subscribe(
        (user: Users) => {
          this.username = user.name;
          this.isUserLoggedIn = true;
        }
      )
      
      auth.userlogout.subscribe(
        () => {
          this.username = '';
          this.isUserLoggedIn = false;
        }
      )
    }



  ngOnInit() {
    this.isUserLoggedIn = this.auth.isUserLoggedId();
    if(this.isUserLoggedIn){
      const user = this.auth.getUser();
      this.username = user.name
      this.id = user.id
      this.brandService.definitions(this.id).subscribe(
        a => {
          
          this.definitions = a['data'][0]

          this.primaryStyle = {
            'color':  this.definitions['primary'],
          }
          this.secondaryStyle = {
            'color':  this.definitions['secondary'],
          }
          this.secondaryBorder = {
            'border-color':  this.definitions['secondary'],
          }

        }
      )
    }
  }
  setPrimaryStyle(){
    return this.primaryStyle;
  }
  setSecondaryBorder(){
    return this.secondaryBorder;
  }
  setSecondaryStyle(){
    return this.secondaryStyle;
  }

  logout(e) {
    e.preventDefault();
    this.auth.logOut();
    this.router.navigate(['login']);
  }
}
