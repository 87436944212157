import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngbd-carousel-config',
  templateUrl: './ngbd-carousel-config.component.html',
  styleUrls: ['./ngbd-carousel-config.component.scss']
})
export class NgbdCarouselConfigComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

