import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Brand } from '../classes/brand';


@Injectable({
  providedIn: 'root'
})
export class BrandService {
  
  brand: Brand[] = [];
  private BRAND = environment.restUrl+'/client/brand';
  

	constructor(private http: HttpClient) {}

  
  	getBrand() {
		return this.http.post(this.BRAND, {});
	}

	doBrand(brand_id: number):Observable<any> {
		var formData = {			
			brand_id: brand_id,	
		}
		return this.http.post(this.BRAND + '/single', formData, {});
	}

	definitions(brand_id: number){
		var formData = {			
			client_id: brand_id,	
		}
		return this.http.post(this.BRAND + '/definitions', formData, {});
	}
	
	updateLogo(files: File, brand): Observable<any>{
		const formData: FormData = new FormData();
		formData.append('logo', files);
		formData.append('id', brand.id);
		formData.append('primary', brand.definitions.primary);
		formData.append('secondary', brand.definitions.secondary);
		console.log(formData)
		return this.http.post( this.BRAND + '/logo', formData, {
			
		});
	}
	updateBrand(brand): Observable<any>{
		
		var formData = {
			id: brand.id,
			client_id: brand.client_id,
			primary: brand.definitions.primary,
			secondary: brand.definitions.secondary
		
		}
		
		return this.http.post( this.BRAND + '/upload', formData, {
			
		});
		
	}
}

